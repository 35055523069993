<template>
  <div>
    <div class="row">
      <div class="col-xl-6 col-lg-6 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("studentInfo.title") }}
          </template>
          <template v-slot:toolbar>
            <button class="btn btn-icon btn-success" @click="editStudentData">
              <i class="fa fa-pen"></i>
            </button>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <tbody>
              <tr>
                <td>{{ $t("studentInfo.data.inn") }}</td><td>{{student.inn}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.firstName") }}</td><td>{{student.firstName}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.name") }}</td><td>{{student.name}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.patronymic") }}</td><td>{{student.patronymic}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.birthDate") }}</td><td>{{student.birthDateValue}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.sex") }}</td><td>{{showSex(student.sex)}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.birthCertificateNumber") }}</td><td>{{student.birthCertificateNumber}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.livingAddress") }}</td><td>{{student.livingAddress}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.phoneNumber") }}</td><td>{{student.phoneNumber}}</td>
              </tr>
              <tr>
                <td>{{ $t("studentInfo.data.whatsApp") }}</td><td>{{student.whatsApp}}</td>
              </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
      </div>
      <div class="col-xl-6 col-lg-6 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("studentInfo.parents.title") }}
          </template>
          <template v-slot:toolbar>
            <button class="btn btn-success" @click="createParent">{{ $t("common.new") }}</button>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th>{{ $t("studentInfo.parents.list.fullName") }}</th>
                <th>{{ $t("studentInfo.parents.list.phoneNumber") }}</th>  
                <th>{{ $t("studentInfo.parents.list.whatsAppNumber") }}</th>  
                <th></th>
              </tr>              
              </thead>
              <tbody>
                <tr v-for="parent in parents" :key="parent.id">
                  <td>{{parent.name}}</td>
                  <td>{{parent.phoneNumber}}</td>
                  <td>{{parent.whatsAppNumber}}</td>
                  <td>
                    <button class="btn btn-icon btn-success" @click="editParent(parent)">
                      <i class="fa fa-pen"></i>
                    </button>
                    <button class="btn btn-icon btn-danger" @click="deleteParent(parent.id)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
    <b-modal ref="preschool-parents-modal">
      <template v-slot:modal-title>
        {{ $t("studentInfo.parents.title") }}
      </template>
      <b-form>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.inn") }}
          </template>
          <b-form-input
              v-model="parent.inn"
              :state="parentModelStates.inn"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.inn }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.firstName") }}
          </template>
          <b-form-input
              v-model="parent.firstName"
              :state="parentModelStates.firstName"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.firstName }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.name")  }}
          </template>
          <b-form-input
              v-model="parent.name"
              :state="parentModelStates.name"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.name }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.patronymic")  }}
          </template>
          <b-form-input
              v-model="parent.patronymic"
              :state="parentModelStates.patronymic"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.patronymic }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.address")  }}
          </template>
          <b-form-input
              v-model="parent.address"
              :state="parentModelStates.address"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.address }}</div>
        </b-form-group> 
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.sex")  }}
          </template>
          <b-form-select
              v-model="parent.sex"
              :state="parentModelStates.sex"
              :options="sexOptions"
              required
          ></b-form-select>
          <div class="invalid-feedback">{{ parentModelSaveError.sex }}</div>
        </b-form-group> 
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.phoneNumber")  }}
          </template>
          <b-form-input
              v-model="parent.phoneNumber"
              :state="parentModelStates.phoneNumber"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.phoneNumber }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.whatsAppNumber")  }}
          </template>
          <b-form-input
              v-model="parent.whatsAppNumber"
              :state="parentModelStates.whatsAppNumber"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.whatsAppNumber }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.workOrganizationName")  }}
          </template>
          <b-form-input
              v-model="parent.workOrganizationName"
              :state="parentModelStates.workOrganizationName"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.workOrganizationName }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.workPosition")  }}
          </template>
          <b-form-input
              v-model="parent.workPosition"
              :state="parentModelStates.workPosition"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.workPosition }}</div>
        </b-form-group>
        <b-form-group  >
          <template v-slot:label>
            {{ $t("studentInfo.parents.form.workAddress")  }}
          </template>
          <b-form-input
              v-model="parent.workAddress"
              :state="parentModelStates.workAddress"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ parentModelSaveError.workAddress }}</div>
        </b-form-group>

      </b-form>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveParentData" variant="primary" id="preschool-parent-data-save-button">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="closeParentForm" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>

    <b-modal ref="preschool-student-modal">
      <template v-slot:modal-title>
        {{ $t("studentInfo.studentForm.title") }}
      </template>
      <b-form>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.inn") }}
          </template>
          <b-form-input
              v-model="student.inn"
              :state="studentModelStates.inn"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.inn }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.firstName") }}
          </template>
          <b-form-input
              v-model="student.firstName"
              :state="studentModelStates.firstName"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.firstName }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.name") }}
          </template>
          <b-form-input
              v-model="student.name"
              :state="studentModelStates.name"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.name }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.patronymic") }}
          </template>
          <b-form-input
              v-model="student.patronymic"
              :state="studentModelStates.patronymic"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.patronymic }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.birthDate") }}
          </template>
          <b-form-datepicker
              v-model="student.birthDate"
              :state="studentModelStates.birthDate"
              locale="ru"
              start-weekday="1"
              required
          ></b-form-datepicker>
          <div class="invalid-feedback">{{ studentModelSaveError.birthDate }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.sex") }}
          </template>
          <b-form-select
              v-model="student.sex"
              :state="studentModelStates.sex"
              :options="sexOptions"
              required
          ></b-form-select>
          <div class="invalid-feedback">{{ studentModelSaveError.sex }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.birthCertificateNumber") }}
          </template>
          <b-form-input
              v-model="student.birthCertificateNumber"
              :state="studentModelStates.birthCertificateNumber"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.birthCertificateNumber }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.livingAddress") }}
          </template>
          <b-form-input
              v-model="student.livingAddress"
              :state="studentModelStates.livingAddress"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.livingAddress }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.phoneNumber") }}
          </template>
          <b-form-input
              v-model="student.phoneNumber"
              :state="studentModelStates.phoneNumber"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.phoneNumber }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            {{ $t("studentInfo.data.whatsApp") }}
          </template>
          <b-form-input
              v-model="student.whatsApp"
              :state="studentModelStates.whatsApp"
              required
          ></b-form-input>
          <div class="invalid-feedback">{{ studentModelSaveError.whatsApp }}</div>
        </b-form-group>
        
      </b-form>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="saveStudentIno" variant="primary" id="preschool-student-info-save-button">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="closeStudentForm" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "PreschoolStudentInfo",
  components: {
    KTPortlet
  },
  data() {
    return {
      studentId: this.$route.params.id,
      student: {},
      studentModelStates:{},
      studentModelSaveError: {},
      parents: [],
      parent:{},
      parentModelStates:{},
      parentModelSaveError: {},
      sexOptions:DictionariesService.sexOptions()
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData() {
      let $this = this;
      
      ApiService.querySecured("preschoolStudent/info", {
        params: {studentToGroupId: this.$route.params.id}
      }).then(({data}) => {
        $this.student = data.student;
        $this.parents = data.parents;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    /*parent*/
    createParent(){
      this.$refs["preschool-parents-modal"].show();
    },
    editParent(item){
      let $this = this;
      ApiService.querySecured("preschoolStudent/findParent", {params: {id: item.id}})
          .then(({data}) => {
            $this.parent = data;
            $this.$refs["preschool-parents-modal"].show();
          }).catch(({response}) => {
        console.log(response);
      });
    },
    saveParentData(evt){
      let submitButton = document.getElementById('preschool-parent-data-save-button');
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");
      evt.preventDefault();
      
      let $this = this;
      this.parentModelStates = {};
      this.parentModelSaveError = {};
      this.parent.studentToGroupId = this.$route.params.id;
      ApiService.postSecured("preschoolStudent/saveParent", this.parent)
          .then(function () {
            $this.$refs["preschool-parents-modal"].hide();
            $this.reloadData();
            $this.cleanParentModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.parentModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.parentModelStates[i] = false;
              }
            }
          })
          .finally(function () {
            submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
          });
      
    },
    closeParentForm(){
      this.$refs["preschool-parents-modal"].hide();
      this.cleanParentModel();
    },
    cleanParentModel() {
      this.parent = {};
    },
    deleteParent(id) {
      if(!confirm("Удалить?")){
        return;
      }
      let $this = this;
      ApiService.postSecured("preschoolStudent/deleteParent", {id : id})
          .then(function () {
            $this.reloadData();
          })
          .catch(({response}) => {
            console.log(response);
          });

    },
    /*student*/
    saveStudentIno(evt){
      let submitButton = document.getElementById('preschool-student-info-save-button');
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");
      evt.preventDefault();

      let $this = this;
      this.studentModelStates = {};
      this.studentModelSaveError = {};
      ApiService.postSecured("preschoolStudent/saveStudentInfo", this.student)
          .then(function () {
            $this.$refs["preschool-student-modal"].hide();
            $this.reloadData();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.studentModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.studentModelStates[i] = false;
              }
            }
          })
          .finally(function () {
            submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
          });

    },
    editStudentData(){
      this.$refs["preschool-student-modal"].show();
    },
    closeStudentForm(){
      this.$refs["preschool-student-modal"].hide();
    },
    showSex(sexId){
      for (let i in this.sexOptions){
        let sex = this.sexOptions[i];
        
        if(sex.value == sexId){
          return sex.text;
        }       
      }
      
      return '';
    }
  }
};
</script>
<script setup>
</script>